import React, { FC } from 'react'
import { RadioButton } from '@progress/kendo-react-inputs';
interface Props {
    id: string
    name: string
    value: string,
    checked: boolean,
    label: string,
    setChecked: (value: boolean) => void
    setValue: (value: string) => void
}
const inputRadio: FC<Props> = (props) => {
    const radioChanged = (event: any) => {
        props.setChecked((props.value === "0" && event.value === "0") || (props.value === "1" && event.value === "1"))
        props.setValue(event.value !== "0" ? event.value : "0")
    }
    return (
        <RadioButton
            id={props.id}
            name={props.name}
            label={props.label}
            value={props.value}
            checked={props.checked}
            onChange={radioChanged}
        />)
}

export default inputRadio
